<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form data-vv-scope="deParaForm" method="post">
      <div class="row justify-content-md-center p-3">

        <div class="col-12 col-md-12">

          <!-- De -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 col-md-12 form-group px-2">
                <label class="small grey-label">{{  $t('form.dePara.de')  }}</label>
                <v-select class="grey-field" :multiple="false" name="formData.de" label="nome" :clearable="false" :searchable="true"
                          v-model="formData.de" :options="originDestinationList" @search="originDestinationFetchOptions"
                          :filter-by="originDestinationFilter" v-validate="{ required: true }"
                >
                  <template slot="no-options" slot-scope="{searching, search }">
                    <em v-if="!searching" style="opacity: 0.5;">
                      Digite para buscar uma origem/destino
                    </em>
                    <em v-else style="opacity: 0.5;">
                      Nenhuma opção encontrada para {{ search }}
                    </em>
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small v-if="errors.first('formData.para')" class="royalc-error-field">{{ errors.first('formData.para') }}</small>
                </transition>
              </div>
                <div class="col-lg-12 col-md-12 form-group px-2">
                  <label class="small grey-label">{{  $t('form.dePara.para')  }}</label>
                  <v-select class="grey-field" :multiple="true" name="formData.para" label="nome" :clearable="false" :searchable="true"
                            v-model="formData.para" :options="originDestinationList" @search="originDestinationFetchOptions"
                            :filter-by="originDestinationFilter" v-validate="{ required: true }"
                  >
                    <template slot="no-options" slot-scope="{searching, search }">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar uma origem/destino
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhuma opção encontrada para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.para')" class="royalc-error-field">{{ errors.first('formData.para') }}</small>
                  </transition>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Submit -->
        <div class="col-12">
          <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">Salvar</button>
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">Cancelar</button>
        </div>

      </div>
    </form>
  </div>
</template>

<script>
// Components
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import DeParaService from '@/services/DeParaService'
import OriginDestinationService from '@/services/OriginDestinationService'

export default {
  name: 'DeParaForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.dePara') + ' - %s'
    }
  },
  data () {
    return {
      originDestinationList: [],
      formData: {
        tipo: 1,
        de: null,
        para: null
      },
      formErrors: {},
      isLoading: true,
      fullPage: true,
      // List of options to select on select input
      submitted: false
    }
  },
  components: {
    Loading,
    vSelect
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isLoading = true

    let promises = [
      OriginDestinationService.getOriginsDestinations()
    ]

    Promise.all(promises).then((values) => {
      let res = values[0]
      _this.hasData = res.data.hasData
      _this.originDestinationList = res.data.data

      // If is edit populate values
      let id = this.$route.params.id

      if (id) {
        DeParaService.get(id).then((res) => {
          _this.formData = res.data.data
          _this.isLoading = false
        })
      } else {
        _this.isLoading = false
      }
    }).catch(() => {
      _this.isLoading = false
    })
  },
  methods: {
    originDestinationFilter (option, label, search) {
      let temp = search.toLowerCase()

      return (this.$util.slugify(option.nome).toLowerCase().indexOf(this.$util.slugify(temp)) > -1 || this.$util.slugify(option.sigla).toLowerCase().indexOf(this.$util.slugify(temp)) > -1)
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    originDestinationFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      OriginDestinationService.getOriginsDestinations(filters).then(res => {
        this.originDestinationList = res.data.data
        loading(false)
      })
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'DeParaIndex' })
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('deParaForm').then(valid => {
        if (valid) {
          _this.isLoading = true

          let data = Object.assign({}, _this.formData)
          let selecionados = []
          selecionados = data.para.map(x => (x.id))
          data.para = selecionados
          data.de = data.de.id

          let id = this.$route.params.id
          if (id) {
            DeParaService.put(id, data).then(response => {
              _this.$router.push({ name: 'DeParaIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            DeParaService.post(data).then(response => {
              _this.$router.push({ name: 'DeParaIndex' })
            }).finally(() => {
              _this.isLoading = false
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
</style>
